<template>
  <dialog-view
      :open="localOperationGroup!==null"
      @cancel="localOperationGroup=null"
      width="1280"
      :title="localOperationGroup===null ? '' : 'Хозяйственная операция #'+localOperationGroup.id"
  >
    <template v-slot:header>
      <div v-if="localOperationGroup!==null" class="mx-1 mb-n3">
        <v-row no-gutters>
          <v-col cols="auto" class="pr-4">
            <field-view
                label="Дата"
                type="dateTime"
                :value="localOperationGroup.created"
                :url="'/api/finance/operationGroup/'+localOperationGroup.id+'/'" field="created"
            />
          </v-col>
          <v-col cols="auto" class="pr-4">
            <label-view title="Тип">
              <field-renders :item="localOperationGroup" :header="{text:'Тип', value:'operationGroupType', type:'operationGroupType_detail'}"/>
            </label-view>
          </v-col>
          <v-col cols="auto" class="pr-4">
            <label-view title="Сумма / Скидка">
              <field-renders :item="localOperationGroup" :header="{type:'operationGroupSumDiscount'}"/>
            </label-view>
          </v-col>
          <v-col cols="auto" class="pr-4">
            <label-view title="Итого">
              <field-renders :item="localOperationGroup" :header="{type:'operationGroupTotal'}"/>
            </label-view>
          </v-col>
          <v-col class="mb-3">
            <field-view
                label="Комментарий" :value="localOperationGroup.comment"
                :url="'/api/finance/operationGroup/'+localOperationGroup.id+'/'" field="comment" type="textarea"
                class="mb-0"
            />
          </v-col>
          <v-col cols="12" class="mb-3">
            <finance-operation-group-actions :operation-group="localOperationGroup" @storn="localOperationGroup=null"/>
          </v-col>
        </v-row>
        <v-tabs v-model="tabSelected" background-color="transparent">
          <v-tab>Аналитика</v-tab>
          <v-tab>Операции</v-tab>
        </v-tabs>
      </div>
    </template>
    <template v-slot:content>
      <div v-if="localOperationGroup!==null">
        <div v-if="tabSelected===0">
          <field-renders :item="localOperationGroup" :header="{text:'Аналитика', sortable:false, type:'operationGroupAnalytics_detail'}"/>
        </div>
        <div v-if="tabSelected===1">
          <div v-for="(v,k) in localOperationGroup.operations" :key="k" :class="k===0 ? '' : 'pt-3'">
            <div class="body-1 font-weight-medium mb-1">{{v.operationType.name}}</div>
            <v-row class="rowXSmall">
              <v-col>
                <div class="caption">Сумма</div>
                <div class="body-1">{{$tools.price(v.amount)}}</div>
              </v-col>
              <v-col>
                <div class="caption">Скидка</div>
                <div :class="'body-1 '+(v.discount<1 ? 'grey--text': '')">{{$tools.price(v.discount)}}</div>
              </v-col>
              <v-col>
                <div class="caption">Количество</div>
                <div :class="'body-1 '+(v.discount<2 ? 'grey--text': '')" class="body-1">{{v.count}}</div>
              </v-col>
              <v-col cols="12" class="pt-0">
                <field-renders :item="localOperationGroup" :header="{text:'Аналитика', type:'operationAnalytics_detail'}"/>
              </v-col>
            </v-row>
            <v-divider v-if="localOperationGroup.operations.length-1>k" class="mt-3"/>
          </div>
        </div>
      </div>
    </template>
  </dialog-view>
</template>

<script>
import DialogView from "@/components/DialogView.vue";
import LabelView from "@/components/LabelView.vue";
import FieldView from "@/components/FieldView.vue";
import FieldRenders from "@/componentsV2/base/FieldRenders.vue";
import FinanceOperationGroupActions from "@/views/Finance/FinanceOperationGroupActions.vue";

export default {
  components: {
    FinanceOperationGroupActions,
    FieldRenders,
    FieldView,
    LabelView,
    DialogView,
  },
  props:['operationGroup'],
  data: () => ({
    localOperationGroup: null,
    tabSelected:0
  }),
  watch:{
    operationGroup(){
      this.localOperationGroup = this.operationGroup;
    },
    localOperationGroup(){
      this.$emit('update:operationGroup', this.localOperationGroup)
    }
  }
}
</script>