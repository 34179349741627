<template>
  <div>
    <n-table-view
        ref="table"
        class="table6"
        name="Список"
        :genRequestBuilder="genRequestBuilder"
        :height="height"
        :headers="[
        {text:'ID', value:'id', type:'integer'},
        {text:'Дата', value:'created', type:'dateTime'},
        {text:'Тип', value:'operationGroupType', type:'operationGroupType'},
        {text:'Операции', sortable:false, type:'operationGroupOperations'},
        {text:'Итого', sortable:false, type:'operationGroupTotal'},
        {text:'Аналитика', sortable:false, type:'operationGroupAnalytics'},
      ]"
    >
      <template v-slot:barActions>
        <slot name="barActions"/>
      </template>
      <template v-slot:item="{item, headers}">
        <tr @click="operationGroup=item" :data-id="item.id">
          <td v-for="v in headers" :key="v.text">
            <field-renders :item="item" :header="v" :class="$tools.isEmpty(v.class) ? null : v.class"/>
          </td>
        </tr>
      </template>
    </n-table-view>
    <finance-operation-group :operation-group.sync="operationGroup"/>
  </div>
</template>

<script>
import FieldRenders from "@/componentsV2/base/FieldRenders.vue";
import NTableView from "@/componentsV2/base/NTableView.vue";
import {
  Entity_AnalyticType,
  Entity_AnalyticValueFrom, Entity_AnalyticValueGroup, Entity_AnalyticValueTo,
  Entity_Operation,
  Entity_OperationGroup, Entity_OperationGroupType, Entity_OperationType,
} from "../../../EntityStoreCacheService";
import FinanceOperationGroup from "@/views/Finance/FinanceOperationGroup.vue";

export default {
  components: {
    FinanceOperationGroup,
    NTableView,
    FieldRenders,
  },
  props:['height','disabled','genRequestBuilderParams'],
  data: () => ({
    operationGroup:null
  }),
  methods:{
    load(){
      this.$refs.table.load();
    },

    genRequestBuilder(page, onPage, sortFields){
      let rb = new Entity_OperationGroup()
          .selects(Entity_OperationGroup.id)
          .selects(Entity_OperationGroup.created)
          .selects(Entity_OperationGroup.comment)

          .selects([Entity_OperationGroup.fixedBy, Entity_OperationGroup.id].join('.'))
          .selects([Entity_OperationGroup.fixedBy, Entity_OperationGroup.created].join('.'))

          .selects([Entity_OperationGroup.fixed, Entity_OperationGroup.id].join('.'))
          .selects([Entity_OperationGroup.fixed, Entity_OperationGroup.created].join('.'))

          .selects([Entity_OperationGroup.reverseBy, Entity_OperationGroup.id].join('.'))
          .selects([Entity_OperationGroup.reverseBy, Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.id].join('.'))
          .selects([Entity_OperationGroup.reverseBy, Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.name].join('.'))

          .selects([Entity_OperationGroup.reverse, Entity_OperationGroup.id].join('.'))
          .selects([Entity_OperationGroup.reverse, Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.id].join('.'))
          .selects([Entity_OperationGroup.reverse, Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.name].join('.'))

          .selects([Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.id].join('.'))
          .selects([Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.valueName].join('.'))
          .selects([Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.value].join('.'))
          .selects([Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.analyticType, Entity_AnalyticType.id].join('.'))
          .selects([Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.analyticType, Entity_AnalyticType.name].join('.'))
          .selects([Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.analyticType, Entity_AnalyticType.sort].join('.'))

          .selects([Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.id].join('.'))
          .selects([Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.name].join('.'))
          .selects([Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.reverseBys, Entity_OperationGroupType.id].join('.'))
          .selects([Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.reverseBys, Entity_OperationGroupType.name].join('.'))

          .selects([Entity_OperationGroup.operations, Entity_Operation.id].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.amount].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.discount].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.count].join('.'))

          .selects([Entity_OperationGroup.operations, Entity_Operation.operationType, Entity_OperationType.id].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.operationType, Entity_OperationType.name].join('.'))

          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueFroms, Entity_AnalyticValueFrom.valueName].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueFroms, Entity_AnalyticValueFrom.value].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueFroms, Entity_AnalyticValueFrom.analyticTypeFrom, Entity_AnalyticType.id].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueFroms, Entity_AnalyticValueFrom.analyticTypeFrom, Entity_AnalyticType.name].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueFroms, Entity_AnalyticValueFrom.analyticTypeFrom, Entity_AnalyticType.sort].join('.'))

          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueTos, Entity_AnalyticValueTo.valueName].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueTos, Entity_AnalyticValueTo.value].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueTos, Entity_AnalyticValueTo.analyticTypeTo, Entity_AnalyticType.id].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueTos, Entity_AnalyticValueTo.analyticTypeTo, Entity_AnalyticType.name].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueTos, Entity_AnalyticValueTo.analyticTypeTo, Entity_AnalyticType.sort].join('.'))

      rb.page(page).onPage(onPage)

      rb = this.genRequestBuilderParams(rb);
      sortFields.forEach(v=>{
        rb.order(v.field, v.isAsc)
      })

      return rb;
    },
  }
}
</script>