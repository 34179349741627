<template>
  <layout-view>
    <template v-slot:content>
      <progress-page v-if="state.isLoading"/>
      <div v-else class="ma-3">
        <table class="tableBordered">
          <tr>
            <td rowspan="2">Хозяйственная операция</td>
            <td rowspan="2">Аналитика</td>
            <td rowspan="2">Операция</td>
            <td colspan="2">Дебет</td>
            <td colspan="2">Кредит</td>
          </tr>
          <tr>
            <td>Счет Дебет</td>
            <td>Аналитики</td>
            <td>Счет Кредит</td>
            <td>Аналитики</td>
          </tr>
          <tr v-for="item in items" :key="item.id">
            <td class="pl-2"><chip-tooltip-text :text="item.name" :info="'#'+item.id"/></td>
            <td>
              <chip-tooltip-text v-for="(v, i) in item.analyticTypes" :key="i" :text="v.name" :info="'#'+v.id" chipClass="mr-1 mb-1"/>
            </td>
            <td class="pa-0" colspan="13">
              <table class="tableBordered1" v-for="operationType in item.operationTypes" :key="operationType.id">
                <tr>
                  <td><chip-tooltip-text :text="operationType.name" :info="'#'+operationType.id"/></td>
                  <td><chip-tooltip-text :text="operationType.accountPlanFrom.sid" :info="'#'+operationType.accountPlanFrom.id"/></td>
                  <td>
                    <v-sheet min-height="100">
                      <chip-tooltip-text v-for="v in operationType.accountPlanFrom.analyticTypes" :key="v.id" :text="v.name" :info="'#'+v.id" chipClass="mb-2 mr-2"/>
                    </v-sheet>
                  </td>
                  <td><chip-tooltip-text :text="operationType.accountPlanTo.sid" :info="'#'+operationType.accountPlanTo.id"/></td>
                  <td>
                    <chip-tooltip-text v-for="v in operationType.accountPlanTo.analyticTypes" :key="v.id" :text="v.name" :info="'#'+v.id" chipClass="mb-2 mr-2"/>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </template>
    <template v-slot:footer>
    </template>
  </layout-view>
</template>

<script>
import LayoutView from "@/components/LayoutView";
import ProgressPage from "@/components/ProgressPage.vue";
import {
  Entity_AccountPlan,
  Entity_AnalyticType,
  Entity_OperationGroupType,
  Entity_OperationType
} from "../../../EntityStoreCacheService";
import State from "@/plugins/state";
import ChipTooltipText from "@/componentsV2/base/ChipTooltipText.vue";
export default {
  components: {ChipTooltipText, ProgressPage, LayoutView},
  data: () => ({
    state: new State(),
    items:[]
  }),
  mounted() {
    new Entity_OperationGroupType()
        .selects(Entity_OperationGroupType.id)
        .selects(Entity_OperationGroupType.name)

        .selects([Entity_OperationGroupType.analyticTypes, Entity_AnalyticType.id].join('.'))
        .selects([Entity_OperationGroupType.analyticTypes, Entity_AnalyticType.name].join('.'))

        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.id].join('.'))
        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.name].join('.'))

        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanFrom, Entity_AccountPlan.id].join('.'))
        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanFrom, Entity_AccountPlan.sid].join('.'))

        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanFrom, Entity_AccountPlan.analyticTypes, Entity_AnalyticType.id].join('.'))
        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanFrom, Entity_AccountPlan.analyticTypes, Entity_AnalyticType.name].join('.'))

        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanTo, Entity_AccountPlan.id].join('.'))
        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanTo, Entity_AccountPlan.sid].join('.'))

        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanTo, Entity_AccountPlan.analyticTypes, Entity_AnalyticType.id].join('.'))
        .selects([Entity_OperationGroupType.operationTypes, Entity_OperationType.accountPlanTo, Entity_AccountPlan.analyticTypes, Entity_AnalyticType.name].join('.'))

        .order(Entity_OperationGroupType.id, true)
        .page(1)
        .onPage(1000)
        .addStateCallback((v)=> this.state.setRequestBuilderState(v))
        .addStateCallback((v)=>{
          if(v.success) this.items = v.items;
        })
        .request()


    /*
    this.graphqlApi.request(
        '/api/finance/graphql/',
        `
query Query {
  operationGroupTypes(orderQL:"id asc") {
    count
    items {
      id
      name
      analyticTypes{
        id
        name
      }
      operationTypes {
        id
        name
        accountPlanFrom {
          id
          sid
          analyticTypes{
            id
            name
          }
        }
        accountPlanTo {
          id
          sid
          analyticTypes{
            id
            name
          }
        }
      }
    }
  }
}
        `,
        {authToken:Session.token()},
        (d)=>{
          this.items = d.operationGroupTypes.items;
        },
        ()=>{
          this.$store.commit('alertError', 'Ошибка загрузки данных.Если ошибка повторяется, обратитесь в тех. поддержку');
        }
    );

     */
  }
}
</script>

<style scoped lang="scss">
.tableBordered {
  border-collapse: collapse;
  td{
    border: 1px solid #CCC;
    font-size: 14px;
    padding: 10px;
    min-width: 250px;
    width: 250px;
    max-width: 250px;
    text-align: left;
  }
  .tableBordered1{
    border-collapse: collapse;
    margin-left: -1px;
    margin-bottom: -1px;
    td{
      border-left: none;
      border-top: none;
      height: initial !important;
    }
  }
}
</style>