<template>
  <div>
    <span v-for="(v,k) in values" :key="k">
      <span v-if="isRawValue===true">
        {{v.value}}
      </span>
      <span v-else>
        <span v-if="typeof v.url==='undefined'">{{v.valueName}}</span>
        <a v-else :href="v.url" target="_blank" class="text-decoration-none">
          <v-icon small class="mr-2">mdi-open-in-new</v-icon>{{v.valueName}}
        </a>
      </span>
      <span v-if="k<values.length-1">,</span>
    </span>
  </div>
</template>
<script>
export default {
  props:['name','operation','urlMask','isRawValue'],
  data: () => ({
    values:[],
  }),
  mounted(){
    this.valueFind();
  },
  watch:{
    operation(){
      this.valueFind();
    }
  },
  methods:{
    valueFind(){
      if(this.$tools.isEmpty(this.name))
        return [];

      let arr = [];
      this.operation.analyticValueFroms.forEach(v=>{
        if(v.analyticTypeFrom.name===this.name)
          arr.push({value:v.value, valueName:v.valueName});
      })
      this.operation.analyticValueTos.forEach(v=>{
        if(v.analyticTypeTo.name===this.name)
          arr.push({value:v.value, valueName:v.valueName});
      })
      this.operation.operationGroup.analyticValueGroups.forEach(v=>{
        if(v.analyticType.name===this.name)
          arr.push({value:v.value, valueName:v.valueName});
      })
      let arr1 = [];
      arr.forEach(v=>{
        if(arr1.filter(v1=>v1.value===v.value).length===0)
          arr1.push(v);
      })

      if(typeof this.urlMask!=='undefined')
        arr.forEach(v=>{
          v.url = this.urlMask.replace('#id#', v.value)
        })

      this.values = arr1;
    }
  }
}
</script>