<template>
  <div v-if="!operationGroup.storn">
    <v-btn
        v-if="
          operationGroup.reverseBy===null &&
          operationGroup.fixedBy===null &&
          operationGroup.operationGroupType.reverseBys.length>0
        "
        @click="reverseByOpen=true"
        color="error" depressed small class="mr-2"
    >
      <v-icon small class="mr-2">mdi-arrow-u-down-right</v-icon>Возврат
    </v-btn>
    <btn-small-accept
        v-if="
          operationGroup.reverseBy===null &&
          operationGroup.fixedBy===null
        "
        color="error"
        btnClass="mr-2"
        name="Возврат"
        icon="mdi-alert"
        :text="'Будет произведен возврат.<br/>Вы уверены?'"
        @accept="$store.commit('alertWarning', 'В данный момент операция не поддерживается')"
    />
    <btn-small-accept
        v-if="!operationGroup.storn"
        color="error"
        btnClass="mr-2"
        name="Сторнировать"
        icon="mdi-arrow-u-down-right"
        text="Операция будет полностью сторнирована. Вы уверены?"
        @accept="storn()"
    />

    <dialog-view
        :open="reverseByOpen"
        @cancel="reverseByOpen=false"
        title="Выберите способ отмены"
    >
      <template v-slot:content>
        <radios
            name="Хоз.операция"
            :items="operationGroup.operationGroupType.reverseBys"
            @update:select="(v)=>reverseByOpen=v"
        />
      </template>
      <template v-slot:actions>
        <v-btn text color="primary" @click="$store.commit('alertWarning', 'В данный момент операция не поддерживается')">ОК</v-btn>
      </template>
    </dialog-view>
  </div>
</template>

<script>
import BtnSmallAccept from "@/componentsV2/base/BtnSmallAccept.vue";
import DialogView from "@/components/DialogView.vue";
import Radios from "@/componentsV2/base/Radios.vue";
import State from "@/plugins/state";

export default {
  components: {Radios, DialogView, BtnSmallAccept},
  props:['operationGroup'],
  data: () => ({
    reverseByOpen: false,
    reverseBy:null,
    stornState:new State()
  }),
  methods:{
    storn(){
      /*
      axios.put(Api.host+'/finance/operationGroup/'+this.operationGroup.id+'/', {
        storn:true,
      }).then(()=>{
        this.$emit('storn');
      }).catch(()=>{
      })

       */
    }
  }
}
</script>