<template>
  <v-list class="pa-0" nav>
    <v-list-item
        v-for="item in cats" :key="item.id"
        @click="itemActiveAction(item)"
        class="mb-0 pa-0"
    >
      <v-list-item-content class="py-0 px-2">
        <v-row no-gutters class="align-center">
          <v-col cols="auto">
            <v-checkbox
                :indeterminate="itemIntermediateActive(item)"
                :input-value="itemActive(item)"
                color="primary" class="mb-2" dense hide-details />
          </v-col>
          <v-col cols="auto" class="mr-2"><v-icon :color="item.color" size="18">{{item.icon}}</v-icon></v-col>
          <v-col class="body-2">{{item.id}}</v-col>
          <v-col cols="auto">
            <v-btn @click.stop="groupsExpands.filter(v=>v.id===item.id).length>0 ? groupsExpands = groupsExpands.filter(v=>v.id!==item.id) : groupsExpands.push(item)" icon small>
              <v-icon>{{groupsExpands.filter(v=>v.id===item.id).length>0 ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="groupsExpands.filter(v=>v.id===item.id).length>0" cols="12" style="padding-left:29px">
            <div v-for="item1 in item.items" :key="item1.id">
              <v-row @click.stop="subItemActiveAction(item1)" no-gutters class="align-center">
                <v-col cols="auto">
                  <v-checkbox
                      :input-value="subItemActive(item1)"
                      color="primary" class="mb-2" dense hide-details />
                </v-col>
                <v-col style="margin-left:-3px" class="body-2">
                  {{genName(item, item1)}}
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>

export default {
  props:['select','cats'],
  data: () => ({
    localSelect:[],
    groupsExpands:[],
  }),
  watch: {
    select() {
      this.localSelect = this.select;
    },
    localSelect() {
      this.$emit('update:select', this.localSelect)
    }
  },
  methods:{
    itemActive(item){
      let count = 0;
      item.items.forEach(v=>{
        if(this.localSelect.filter((v1)=>v1.id===v.id).length>0)
          count++;
      })
      return count===item.items.length && item.items.length>0;
    },
    itemIntermediateActive(item){
      let count = 0;
      item.items.forEach(v=>{
        if(this.localSelect.filter((v1)=>v1.id===v.id).length>0)
          count++;
      })
      return count!==item.items.length && item.items.length>0 && count>0;
    },
    itemActiveAction(item){
      if(this.itemActive(item)){
        item.items.forEach(v=>{
          this.localSelect = this.localSelect.filter((v1)=>v1.id!==v.id)
        })
      } else {
        item.items.forEach(v=>{
          this.localSelect.push(v)
        })
      }
    },
    subItemActive(item){
      return this.localSelect.filter((v1)=>v1.id===item.id).length>0;
    },
    subItemActiveAction(item){
      this.localSelect.filter((v)=>v.id===item.id).length===0 ?
          this.localSelect.push(item) :
          this.localSelect = this.localSelect.filter((v)=>v.id!==item.id)
    },
    genName(item, item1){
      let name = item.id;
      let name1 = item1.name;

      if(name1.indexOf(name)===0 && name1.length!==name.length)
        return name1.replace(name, '').trim().charAt(0).toUpperCase()+name1.replace(name, '').trim().slice(1);
      return name1
    }
  }
}
</script>